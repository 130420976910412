<template>
  <div class="econnav">
    <div class="cityselectBox">
      <div class="label">
        城市选择:
      </div>
      <el-select class="cityselect" v-model="areaId" placeholder="请选择" size="mini">
        <el-option v-for="item in citylist" :key="item.id" :label="item.label" :value="item.id"
          :disabled="item.disabled">
        </el-option>
      </el-select>
    </div>
    <div class="aroundyear">
      <div class="label">
        年份选择:
      </div>
      <div class="cityeconblock">
        <el-date-picker v-model="startyear" type="year" :picker-options="pickerOptions" size="mini" class="yearselect"
          format="yyyy" placeholder="起始年">
        </el-date-picker>
      </div>
      <div>
        至
      </div>
      <div class="cityeconblock">
        <el-date-picker v-model="endyear" type="year" size="mini" class="yearselect" format="yyyy"
          :picker-options="pickerOptions" placeholder="结束年">
        </el-date-picker>
      </div>
    </div>
    <div>
      <el-button size="mini" type="primary" @click="cidRangeyear" icon="el-icon-search">查询</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "VueElementTupaiEconwhere",
  props: {
    //范围
    rangeYear: {
      type: Array,
      default: function () {
        return [];
      },
    },
    curAreaId: {
      type: Number,
    },
    citylist: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      areaId: '',
      startyear: "0",
      endyear: "0",
      pickerOptions: {},
    };
  },

  watch: {
    //当前区id 触发
    areaId(nv) {
      this.$emit("change-areaid", nv);
    },

    curAreaId(nv) {

      if (nv === 0) {
        this.areaId = '';
      } else {
        this.areaId = nv;
      }
    },
    rangeYear: {
      handler(nv, ov) {
        // console.log('nv', nv, nv.length)
        if (nv.length < 2) {

        } else {
          this.startyear = nv[0].toString();
          this.endyear = nv[1].toString();
          this.pickerOptions = {
            disabledDate(time) {
              return time.getFullYear() < nv[0] || time.getFullYear() > nv[1];
            },
          };
        }
      },
      deep: true,
    },

    // areaId(nv){
    //   console.log('areaId',nv)
    //    this.cidRangeyear()

    // },
    // startyear(nv){
    //   console.log('startyear',nv)
    //    this.cidRangeyear()
    // },
    // endyear(nv){
    //   console.log('endyear',nv)
    //   this.cidRangeyear()
    // },
  },
  mounted() { },

  methods: {
    cidRangeyear() {

      let startyear = this.dateToYear(this.startyear);
      let endyear = this.dateToYear(this.endyear);
      const obj = {
        areaId: this.areaId,
        startyear: startyear,
        endyear: endyear,
      }
      // console.log("=======",obj)
      this.$emit("cid-rangeyear", obj);
    },
    //将插件的date对象转化为年 string类型
    dateToYear(obj) {
      if (typeof obj !== "object") {
        return obj;
      } else {
        return obj.getFullYear().toString();
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.econnav {
  width: 98%;
  margin: 3px auto;
  padding-left: 20px;
  box-sizing: border-box;
  height: 48px;
  background: #f0f3f8;
  border-radius: 4px;
  display: flex;
  justify-content: left;
  align-items: center;

  .cityselectBox {
    display: flex;
    justify-content: left;
    align-items: center;

    .cityselect {
      width: 140px;
      margin-left: 10px;
    }
  }

  .aroundyear {
    margin-left: 20px;
    display: flex;
    align-items: center;

    .yearselect {
      width: 140px;
      margin: 0 10px;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 400;
  }
}
</style>